* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  margin-bottom: 40px;
}

.project-section {
  margin-bottom: 40px;
}

.project-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.project-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.project-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.project-card p {
  font-size: 1em;
  margin-bottom: 10px;
}

.project-card a {
  color: #007bff;
  text-decoration: none;
}

.project-card a:hover {
  text-decoration: underline;
}

.project-card img.project-screenshot {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin-bottom: 10px;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
